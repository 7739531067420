import { render, staticRenderFns } from "./partyBuilding.vue?vue&type=template&id=2a654cc6&scoped=true"
import script from "./partyBuilding.vue?vue&type=script&lang=js"
export * from "./partyBuilding.vue?vue&type=script&lang=js"
import style0 from "./partyBuilding.vue?vue&type=style&index=0&id=2a654cc6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a654cc6",
  null
  
)

export default component.exports